var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.projects)?_c('div',{staticClass:"sales-table"},[_c('DataTable',{staticClass:"p-datatable-sm p-datatable-striped p-datatable-gridlines",attrs:{"value":_vm.projects,"dataKey":"number","paginator":true,"rows":_vm.rowsPerPage,"totalRecords":_vm.totalRecords,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":_vm.$t('show') +
        ' {first} ' +
        _vm.$t('to') +
        ' {last} ' +
        _vm.$t('of') +
        ' {totalRecords} ' +
        _vm.$t('records'),"rowsPerPageOptions":[10, 15, 20, 25, 30],"responsiveLayout":"scroll"},on:{"update:rows":function($event){_vm.rowsPerPage=$event},"page":_vm.onPage}},[_c('Column',{attrs:{"field":"number","header":"#","sortable":"","headerStyle":{ width: '60px' },"bodyStyle":{ 'text-align': 'center' }}}),_c('Column',{attrs:{"f":"","field":"customer.lastname","header":_vm.$t('lastname'),"sortable":""}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }