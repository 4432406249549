<template>
  <div class="dashboard">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <div class="grid">
      <div>
        <Card>
          <template #title>
            Phasenübersicht
          </template>
          <template #content>
            <div class="flex-row">
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_needs_analysis }}</h2>
                <h6>Bedarfsanalyse</h6>
              </div>
              <div class="flex-column">
                <h2>
                  {{ getMeta.count_project_phases_waiting_for_planning }}
                </h2>
                <h6>Warte auf Planung</h6>
              </div>
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_waiting_for_images }}</h2>
                <h6>Warte auf Bilder</h6>
              </div>
            </div>
            <div class="flex-row">
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_planning_successfull }}</h2>
                <h6>Planung erfolgreich</h6>
              </div>
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_sc1_scheduled }}</h2>
                <h6>VK 1 terminiert</h6>
              </div>
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_follow_up }}</h2>
                <h6>Follow Up</h6>
              </div>
            </div>
            <div class="flex-row">
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_sc1_successfull }}</h2>
                <h6>VK erfolgreich</h6>
              </div>
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_sc2_scheduled }}</h2>
                <h6>VK 2 terminiert</h6>
              </div>
              <div class="flex-column">
                <h2>{{ getMeta.count_project_phases_closure }}</h2>
                <h6>Abschluss</h6>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <CockpitTable :projects="projectsInPhaseNeedsAnalysis" />

      <!-- <SalesTable :cardTitle="'Leads'" :phase="'SALES_PHASE_LEAD'" />
      <SalesTable :cardTitle="'Bedarfsanalyse'" :phase="'SALES_PHASE_NEEDS_ANALYSIS'" /> -->
      <!-- <SalesTable :cardTitle="'Lost'" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
// import SalesTable from '@/components/Sales/SalesTable';
import CockpitTable from '@/components/Sales/CockpitTable';

import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';
import Card from 'primevue/card';

export default {
  name: 'DashboardDev',
  components: {
    Card,
    Breadcrumbs,
    // SalesTable,
    CockpitTable,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getAppointments',
      'getEnumValues',
      'getEmployees',
      'getMeta',
      'getProjectsInNeedsAnalysis',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('SALES_COCKPIT') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
    projectsInPhaseNeedsAnalysis() {
      console.log('Computed getProjectsInNeedsAnalysis:', this.getProjectsInNeedsAnalysis);
      return this.getProjectsInNeedsAnalysis;
    },
  },
  methods: {
    ...mapActions(['fetchMeta', 'fetchProjectsInNeedsAnalysis']),
    async loadProjectsData() {
      console.log('loadProjectsData wird ausgefuehrt');
      const params = { page: 0, pageSize: 10, sortField: 'someField', sortOrder: 1 };
      await this.fetchProjectsInNeedsAnalysis(params);
    },
  },
  async created() {},
  async mounted() {
    const params = { page: 0, pageSize: 10, sortField: 'someField', sortOrder: 1 };
    await this.fetchProjectsInNeedsAnalysis(params);
    await this.fetchMeta();
  },
};
</script>

<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  gap: 1em;
  margin-bottom: 1em;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  background: rgba(0, 0, 32, 0.03);
  padding: 0.5em;
  // border: 1px solid red;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}
.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 0.5em;
}

.grid > div {
  padding: 1em;
  border: 2px;
}
.logo {
  width: 88px;
}
.p-card {
  color: $text-color;
}
.data-table-container {
  overflow-x: auto;
}
</style>
