<template>
  <div class="sales-table" v-if="projects">
    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      :value="projects"
      dataKey="number"
      :paginator="true"
      :rows.sync="rowsPerPage"
      :totalRecords="totalRecords"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30]"
      @page="onPage"
      responsiveLayout="scroll"
    >
      <Column
        field="number"
        header="#"
        sortable
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      />

      <Column f field="customer.lastname" :header="$t('lastname')" sortable />

      <!-- <Column field="sales.callState" header="$t('callState')" sortable /> -->
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {
    DataTable,
    Column,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      rowsPerPage: 10,
      totalRecords: 0,
    };
  },
  methods: {
    onPage(event) {},
  },
};
</script>

<style></style>
